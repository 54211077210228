import React, { FC, ReactNode } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Masonry from 'react-masonry-css';

const useStyles = makeStyles<Theme>({
  masonryGrid: {
    display: 'flex',
    marginLeft: -96,
    width: 'inherit',
  },
  masonryColumn: {
    paddingLeft: 96,
    boxSizing: 'border-box',
    backgroundClip: 'padding-box',
  },
});

const MasonryWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  const breakpointCols = {
    default: 2,
    [theme.breakpoints.values.xl]: 2,
    [theme.breakpoints.values.lg]: 2,
    [theme.breakpoints.values.md]: 1,
    [theme.breakpoints.values.sm]: 1,
    [theme.breakpoints.values.xs]: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointCols}
      className={classes.masonryGrid}
      columnClassName={classes.masonryColumn}
    >
      {children}
    </Masonry>
  );
};

export default MasonryWrapper;
