import React, { FC } from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import { useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import Layout from 'src/template/layout';
import Masonry from 'src/components/Masonry';
import SEO from 'src/components/SEO';

const Section = styled('section')({
  backgroundImage: 'url("/backgrounds/img-bg-type-01.svg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
});

const TitleWrapper = styled('div')({
  marginBottom: 75,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Heading = styled(Typography)({
  fontSize: 72,
  marginBottom: 0,
}) satisfies typeof Typography;

const Dot = styled('div')({
  width: 16,
  height: 16,
  margin: ' 0 0 0 59px',
  backgroundColor: '#00dd99',
  borderRadius: '50%',
});

const DotMenu = styled(Typography)({
  fontSize: 32,
}) as typeof Typography;

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const BlogCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: 64,

  '& > img': {
    width: '100%',
    marginBottom: 16,
  },
}));

const Title = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.85)',
  fontSize: 24,
  fontWeight: 700,
}) satisfies typeof Typography;

const Description = styled(Typography)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: '#4a4a4a',
  fontSize: '1.25rem',
}) satisfies typeof Typography;

const DateText = styled(Typography)({
  color: '#858585',
}) satisfies typeof Typography;

// markup
const NewsPage: FC = () => {
  const ghost = useStaticQuery(graphql`
    query {
      allGhostPost(
        filter: { tags: { elemMatch: { name: { eq: "PRESS" } } } }
        sort: { order: DESC, fields: [published_at] }
      ) {
        nodes {
          title
          feature_image
          published_at(formatString: "YYYY.MM.DD")
          plaintext
          ghostId
        }
      }
    }
  `);

  const postList = ghost.allGhostPost.nodes;

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Layout>
      <Section>
        <Container>
          <TitleWrapper>
            <Heading variant="h1">WHAT’S NEW?</Heading>
          </TitleWrapper>
          <Grid>
            <Grid item xs={12} md={9}>
              <Masonry>
                {postList.map(item => (
                  <StyledLink to={`/post/` + item.ghostId} key={item.ghostId}>
                    <BlogCard>
                      <img src={item.feature_image} alt={item.title} />
                      <Title variant="h3">{item.title}</Title>
                      <Description variant="body1">{item.plaintext}</Description>
                      <DateText variant="body1">{item.published_at}</DateText>
                    </BlogCard>
                  </StyledLink>
                ))}
              </Masonry>
            </Grid>
            <Grid item md={3}></Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default NewsPage;


export const Head = () => {
  return <SEO title={'news'} description={"What's new?"} />
}